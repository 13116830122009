<div
  class="spot-modal spot-modal_wide confirm-form-submit--modal loading-indicator--location"
  data-indicator-name="modal"
>
  <div class="spot-modal--header">{{text.board_type}}</div>

  <div class="spot-divider"></div>

  <div class="spot-modal--body spot-container">
    <p
      *ngIf="eeShowBanners"
    >
      <op-enterprise-banner
        [linkMessage]="text.upgrade_to_ee_text"
        [textMessage]="text.teaser_text"
        [moreInfoLink]="text.more_info_ee_link"
        opReferrer="boards"
      ></op-enterprise-banner>
    </p>
    <p
      *ngIf="!eeShowBanners"
      class="spot-body-small"
      [textContent]="text.select_board_type"></p>
    <section class="new-board--section">
      <tile-view
        [tiles]="available"
        [disable]="inFlight"
        (create)="createBoard($event)">
      </tile-view>
    </section>
  </div>

  <div class="spot-action-bar">
    <div class="spot-action-bar--right">
      <button
        type="button"
        class="button button_no-margin spot-action-bar--action"
        (click)="closeMe()"
        >
        {{ text.cancel_button }}
     </button>
    </div>
  </div>
</div>
